export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const INCREMENT_QUANTITY = 'INCREASE';
export const DECREMENT_QUANTITY = 'DECREMENT_QUANTITY';
export const CHECKOUT = 'CHECKOUT';
export const CLEAR_CART = 'CLEAR_CART';
export const UPDATE_SALE_PRICE = 'UPDATE_SALE_PRICE';
export const UPDATE_DELIVERY_COST ='UPDATE_DELIVERY_COST';
export const ADD_ORDER_COMMENT='ADD_ORDER_COMMENT';
export const ORDER_PRODUCTS_STORAGE ='AnalogicOrderProducts';


