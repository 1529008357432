import { getProductTotal } from '../../helpers/order';
import {
  ADD_ORDER_COMMENT,
  ADD_TO_CART,
  CLEAR_CART,
  DECREMENT_QUANTITY,
  ORDER_PRODUCTS_STORAGE,
  REMOVE_FROM_CART,
  UPDATE_DELIVERY_COST,
  UPDATE_SALE_PRICE
} from './Constants.js';

const initialState = { products: [], deliveryCost: 0,comment:'' };

export const initializer = (initialValue = initialState) =>
  JSON.parse(localStorage.getItem(ORDER_PRODUCTS_STORAGE)) || initialValue;

// const getTotal = (quantity, salePrice) => {
//   return (quantity * parseFloat(salePrice)).toFixed(2);
// };
const getExistingProducts = (products, item) => {
  const existingProduct = products.find((product) => product.sku === item.sku);
  return existingProduct;
};
export const reducer = (state, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      const newItem={...action.item};
      newItem.productId=action.item.sku;
      newItem.status='';
     // action.item.productId=action.item._id;
      delete newItem._id ;
      //delete action.item._id ;

      console.log('item,',newItem);
     // console.log('action.item,',action.item);
      if (getExistingProducts(state?.products, newItem)) {
        const updateProducts = state?.products.map((item) =>
          item.sku === newItem.sku
            ? {
                ...item,
                quantity: item.quantity + 1,
                subTotal: getProductTotal(
                  item.quantity + 1,
                  action.item.salePrice
                )
              }
            : item
        );
        return { ...state, products: updateProducts };
      } else {
        return {
          ...state,
          products: [
            ...state.products,
            { ...newItem, quantity: 1, subTotal: newItem.salePrice }
          ]
        };
      }

    case REMOVE_FROM_CART:
      return {
        ...state,
        products: state?.products.filter((item) => item.sku !== action.item.sku)
      };

    case DECREMENT_QUANTITY:
      const existingProduct = getExistingProducts(state?.products, action.item);
      if (existingProduct?.quantity === 1) {
        const filteredProducts = state?.products.filter(
          (item) => item.sku !== action.item.sku
        );
        return { ...state, products: filteredProducts };
      } else {
        const decreaseQuantity = state?.products.map((item) =>
          item.sku === action.item.sku
            ? {
                ...item,
                quantity: item.quantity - 1,
                subTotal: getProductTotal(
                  item.quantity - 1,
                  action.item.salePrice
                )
              }
            : item
        );
        return { ...state, products: decreaseQuantity };
      }

    case UPDATE_SALE_PRICE:
      debugger;
      if (getExistingProducts(state?.products, action.item)) {
        const updatedPrice = state?.products.map((item) =>
          item.sku === action.item.sku
            ? {
                ...item,
                salePrice: action.item.salePrice,
                subTotal: getProductTotal(item.quantity, action.item.salePrice)
              }
            : item
        );
        return { ...state, products: updatedPrice };
      }
      return state;
    case CLEAR_CART:
      return initialState;
    case UPDATE_DELIVERY_COST:
      return { ...state, deliveryCost: action.item };
       case ADD_ORDER_COMMENT:
      return { ...state, comment: action.comment };

    default:
      return state;
  }
};
