import './assets/css/custom.css';
import './assets/css/tailwind.css';
import './assets/css/tailwind.output.css';
import '@pathofdev/react-tag-input/build/index.css';
import 'react-tabs/style/react-tabs.css';

import { Windmill } from '@windmill/react-ui';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import myTheme from './assets/theme/myTheme';
import { BasketCustomerProvider } from './components/customerOrder/context/Customer/BasketCustomerContext';
import { BasketProductsProvider } from './components/customerOrder/context/Products/BasketProductsContext';
import ThemeSuspense from './components/theme/ThemeSuspense';
//import CartState from './context/Cart/CartState';
//import { OrderProvider } from './context/OrderContext';
import { SidebarProvider } from './context/SidebarContext';
import { UserProvider } from './context/UserContext';
// import * as serviceWorker from './serviceWorker';

// if (process.env.NODE_ENV !== "production") {
//   const axe = require("react-axe");
//   axe(React, ReactDOM, 1000);
// }

ReactDOM.render(
  <UserProvider>
     {/* <OrderProvider> */}
    <SidebarProvider>
    <BasketProductsProvider>
      <BasketCustomerProvider>
      <Suspense fallback = {<ThemeSuspense />}>
        <Windmill usePreferences theme = {myTheme}>
       
          <App />
         
        </Windmill>
      </Suspense>
      </BasketCustomerProvider>
      </BasketProductsProvider>
    </SidebarProvider>
    {/* </OrderProvider> */}
  </UserProvider>  ,

  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
