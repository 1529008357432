import React, { createContext, useEffect, useReducer } from 'react';

import { ORDER_PRODUCTS_STORAGE } from './Constants.js';
import { initializer,reducer } from './reducer.js';
export const BasketProductsContext = createContext();

export const BasketProductsProvider = ({ children }) => {
  const [basketProducts,dispatch] = useReducer(reducer, {products:[],deliveryCost:0}, initializer);

  useEffect(() => {
    localStorage.setItem(ORDER_PRODUCTS_STORAGE, JSON.stringify(basketProducts));
  }, [basketProducts]);

  return (
    <BasketProductsContext.Provider
      value = {{
        basketProducts,
        dispatch
      }}
    >
      {children}
    </BasketProductsContext.Provider>
  );
};
