import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { UserContext } from '../../context/UserContext';

const PrivateRoute = ({ children, ...rest }) => {
  const { state } = useContext(UserContext);
  const { userInfo } = state;
  return (
    <Route
      {...rest}
      render = {({ location }) =>
        userInfo?.email ? (
          children
        ) : (
          <Redirect
            to = {{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
