import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { toast,ToastContainer } from 'react-toastify';

const settings={
  position: 'top-center',
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};


const notifySuccess = (message) =>
  toast.success(message, settings);
const notifyError = (message) =>
  toast.error(message, settings);

const notifyMessage = ({message,statusCode}) =>{
  if(!statusCode || statusCode===200 ||statusCode===''){
    notifySuccess(message);
  }
  else{
    notifyError(message);
  }
};

// eslint-disable-next-line no-unused-expressions
<ToastContainer
  position = 'top-center'
  autoClose = {3000}
  hideProgressBar = {false}
  newestOnTop = {false}
  closeOnClick
  rtl = {false}
  pauseOnFocusLoss
  draggable
  pauseOnHover
/>;

export {notifyError,notifyMessage, notifySuccess, ToastContainer };
