export const getProductTotal = (quantity, salePrice) => {
  return (quantity * parseFloat(salePrice)).toFixed(2);
};

export const getOrderSubTotal = (products) => {
  const subTotal = products.reduce(function (preValue, item) {
    return parseFloat( Number(preValue) +Number( item['subTotal'])).toFixed(2);

  }, 0);
  return subTotal;
};

export const getOrderVat = (subTotal) => {
  return (parseFloat(subTotal) * 0.2).toFixed(2);
};

export const getOrderTotals = (subTotal, deliveryCost=0) => {
  const vatTotals = getOrderVat(subTotal);
  return (
    parseFloat(vatTotals) +
    parseFloat(subTotal) +
    parseFloat(deliveryCost||0)
  ).toFixed(2);
};

export const formatAddress=(customerOrder)=>{
 let address= `${customerOrder.addressLine1||' '  } ${ customerOrder.addressLine2 ||' '} `;
 address=`${address.trim() } ${  customerOrder.town||' '}`;
 address=`${address.trim() } ${  customerOrder.postcode||' '}`;
 address=`${address.trim() } ${  customerOrder.country||' '}`;
address= (address||'').trim();
return address;
};

export const isOrderStatusPending=(orderStatus)=>{
  return orderStatus === undefined || orderStatus?.toLowerCase() === 'pending';
 };

 export const isOrderStatusDispatched=(orderStatus)=>{
  return orderStatus === undefined || orderStatus?.toLowerCase() === 'dispatched';
 };